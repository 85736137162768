import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import routerAdmin from './routerAdmin';
import jwtDecode from 'jwt-decode';

Vue.use(VueRouter);

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error)
}

const routes = [
    {
        path: '/',
        redirect: '/login',
        meta: {requireAuth: false},
    },
    {
        path: '/login',
        component: () => import('@/views/spoAdmin/login.vue'),
        meta: {requireAuth: false},
    },
    {
        path: "*",
        redirect: "/404",
        meta: {requireAuth: false},
    },
    {
        path: "/404",
        component: resolve => require.ensure([], () => resolve(require('@/views/notFound.vue'))),
        meta: {requireAuth: false},
    },
    {
        path: "/lackPermissions",
        component: resolve => require.ensure([], () => resolve(require('@/views/lackPermissions.vue'))),
        meta: {requireAuth: false},
    },
    ...routerAdmin,
];

const router = new VueRouter({routes});

if (sessionStorage.getItem('token')) {
    store.commit('set_token', {
        token: sessionStorage.getItem('token'),
        status: true
    });
}
store.commit('set_serverKey', sessionStorage.getItem('serverKey'));
store.commit('set_key', {pkcsType: 'pkcs8', pkcsSize: '1024'});

//路由守卫: 确保用户只能访问有权限的路由

router.beforeEach((to, from, next) => {
    store.commit('CLAER_CANCEL'); // 取消请求
    if (to.matched.some(record => record.meta.requireAuth)) {
        if (store.state.token.token) {
            let roles = to.meta.roles
            if (roles) {
                let flag = false;
                let types = jwtDecode(store.state.token.token).userType;
                for (const type of types) {
                    if (roles.includes(type)) {
                        flag = true;
                        break;
                    }
                }
                if (roles.length == 0) flag = true;
                flag ? next() : next({path: "/lackPermissions"});
            } else {
                next({path: "/404"});
            }
        } else {
            next({path: '/login', query: {redirect: to.fullPath}});
        }
    } else {
        next();
    }
});

export default router;
