//定义路由
export default [
    {
        path: '/spoAdmin',
        component: () => import('@/views/spoAdmin/spoAdmin.vue'),
        meta: {requireAuth: true, roles: ['SA']},
        redirect: '/spoAdmin/business',
        children: [
            {
                path: "/spoAdmin/user",
                component: () => import(/* webpackChunkName: "admin-user" */ '@/views/spoAdmin/subTabs.vue'),
                meta: {requireAuth: true, roles: ['SA']},
                props: (route) => ({currentTab: 'user'}),
                redirect: '/spoAdmin/user/list',
                children: [
                    {
                        path: '/spoAdmin/user/list',
                        component: () => import(/* webpackChunkName: "admin-user" */ '@/views/spoAdmin/user/userManagement/userList.vue'),
                        meta: {requireAuth: true, roles: ['SA']},
                        props: (route) => ({user: route.query.user}),
                    },
                    {
                        path: '/spoAdmin/user/manager',
                        component: () => import(/* webpackChunkName: "admin-user" */ '@/views/spoAdmin/user/userManagement/managerList.vue'),
                        meta: {requireAuth: true, roles: ['SA']},
                    },
                    {
                        path: '/spoAdmin/user/authorityList',
                        component: () => import(/* webpackChunkName: "admin-user" */ '@/views/spoAdmin/user/userManagement/userRoles.vue'),
                        meta: {requireAuth: true, roles: ['SA']},
                        props: (route) => ({}),
                    },
                ]
            },
            {
                path: "/spoAdmin/user/status",
                meta: {requireAuth: true, roles: ['SA']},
                component: () => import(/* webpackChunkName: "admin-user" */ '@/views/spoAdmin/user/userStatus.vue'),
            },
            {
                path: "/spoAdmin/user/bill",
                meta: {requireAuth: true, roles: ['SA']},
                component: () => import(/* webpackChunkName: "admin-user" */ '@/views/spoAdmin/user/bill/billManagement.vue'),
                redirect: '/spoAdmin/user/bill/list',
                children: [
                    {
                        path: '/spoAdmin/user/bill/list',
                        meta: {requireAuth: true, roles: ['SA']},
                        component: () => import(/* webpackChunkName: "admin-user" */ '@/views/spoAdmin/user/bill/billList.vue'),
                        props: (route) => ({user: route.query.user}),
                    },
                    {
                        path: '/spoAdmin/user/bill/moduleProfit',
                        meta: {requireAuth: true, roles: ['SA']},
                        component: () => import(/* webpackChunkName: "admin-user" */ '@/views/spoAdmin/user/bill/profitBill.vue')
                    },
                    {
                        path: '/spoAdmin/user/bill/withdrawMethod',
                        meta: {requireAuth: true, roles: ['SA']},
                        component: () => import(/* webpackChunkName: "admin-user" */ '@/views/spoAdmin/user/bill/withdrawMethod.vue')
                    },
                    {
                        path: '/spoAdmin/user/bill/balanceManagement',
                        meta: {requireAuth: true, roles: ['SA']},
                        component: () => import(/* webpackChunkName: "admin-user" */ '@/views/spoAdmin/user/bill/balanceManagement.vue')
                    },
                ]
            },
            {
                path: "/spoAdmin/user/securityQuestion",
                meta: {requireAuth: true, roles: ['SA']},
                component: () => import(/* webpackChunkName: "admin-user" */ '@/views/spoAdmin/user/securityQuestion/securityQuestion.vue'),
                redirect: '/spoAdmin/user/securityQuestion/list',
                children: [
                    {
                        path: '/spoAdmin/user/securityQuestion/list',
                        meta: {requireAuth: true, roles: ['SA']},
                        component: () => import(/* webpackChunkName: "admin-user" */ '@/views/spoAdmin/user/securityQuestion/securityQuestionList.vue')
                    },
                    {
                        path: '/spoAdmin/user/securityQuestion/group',
                        meta: {requireAuth: true, roles: ['SA']},
                        component: () => import(/* webpackChunkName: "admin-user" */ '@/views/spoAdmin/user/securityQuestion/securityQuestionGroup.vue')
                    },
                ]
            },
            {
                path: '/spoAdmin/business',
                meta: {requireAuth: true, roles: []},
                component: () => import(/* webpackChunkName: "admin-business" */ '@/views/spoAdmin/subTabs.vue'),
                props: (route) => ({currentTab: 'business'}),
                redirect: '/spoAdmin/business/saleVolume',
                children: [
                    {
                        path: '/spoAdmin/business/saleVolume',
                        meta: {requireAuth: true, roles: []},
                        component: () => import(/* webpackChunkName: "admin-business" */ '@/views/spoAdmin/business/saleVolume.vue'),
                    },
                    {
                        path: '/spoAdmin/business/saleList',
                        meta: {requireAuth: true, roles: [ 'SA','AS','AF']},
                        component: () => import(/* webpackChunkName: "admin-business" */ '@/views/spoAdmin/business/saleList.vue'),
                        props: (route) => ({id: route.query.id}),
                    },
                    {
                        path: '/spoAdmin/business/returnsAndExchangesList',
                        meta: {requireAuth: true, roles: [ 'SA','AP','AF']},
                        component: () => import(/* webpackChunkName: "admin-business" */ '@/views/spoAdmin/business/returnsAndExchangesList.vue'),
                        // props: (route) => ({id: route.query.id}),
                    },
                    {
                        path: '/spoAdmin/business/purchaseGoods',
                        meta: {requireAuth: true, roles: ['SA', 'AP','AF']},
                        component: () => import(/* webpackChunkName: "admin-business" */ '@/views/spoAdmin/business/purchaseGoods.vue'),
                    },
                  
                ]
            },
            {
                path: '/spoAdmin/logistics',
                meta: {requireAuth: true, roles: ['SA', 'AS','AF']},
                component: () => import(/* webpackChunkName: "admin-business" */ '@/views/spoAdmin/subTabs.vue'),
                props: (route) => ({currentTab:'logistics'}),
                redirect: '/spoAdmin/logistics/list',
                children: [
                    {
                        path: '/spoAdmin/logistics/list',
                        meta: {requireAuth: true, roles: ['SA', 'AS','AF']},
                        component: () => import(/* webpackChunkName: "admin-business" */ '@/views/spoAdmin/business/logistics.vue'),
                        props: (route) => ({number: route.query.number}),
                    },
                ]
            },
            {
                path: '/spoAdmin/complain',
                meta: {requireAuth: true, roles: []},
                component: () => import(/* webpackChunkName: "admin-business" */ '@/views/spoAdmin/subTabs.vue'),
                props: (route) => ({currentTab:'complain'}),
                redirect: '/spoAdmin/complain/list',
                children: [
                    {
                        path: '/spoAdmin/complain/list',
                        meta: {requireAuth: true, roles: ['SA', 'AS']},
                        component: () => import(/* webpackChunkName: "admin-business" */ '@/views/spoAdmin/business/complainList.vue'),
                        // props: (route) => ({customerId: route.query.customerId, customerType: 'private'}),
                    },
                ]
               
            },
            {
                path: '/spoAdmin/customer',
                meta: {requireAuth: true, roles: ['SA', 'AS']},
                component: () => import(/* webpackChunkName: "admin-partnership" */'@/views/spoAdmin/subTabs.vue'),
                props: (route) => ({currentTab:'customer'}),
                redirect: '/spoAdmin/customer/customerList',
                children: [
                    {
                        path: '/spoAdmin/customer/customerList',
                        meta: {requireAuth: true, roles: ['SA', 'AS']},
                        component: () => import(/* webpackChunkName: "admin-partnership" */ '@/views/spoAdmin/partnership/customer/customerList.vue'),
                        props: (route) => ({customerId: route.query.customerId, customerType: 'private'}),
                    },
                    {
                        path: '/spoAdmin/customer/publicCustomerList',
                        meta: {requireAuth: true, roles: ['SA', 'AS']},
                        component: () => import(/* webpackChunkName: "admin-partnership" */ '@/views/spoAdmin/partnership/customer/customerList.vue'),
                        props: (route) => ({customerId: route.query.customerId,customerType: 'public'}),
                    },
                ]
            },
            {
                path: '/spoAdmin/supplier',
                meta: {requireAuth: true, roles: ['SA', 'AP']},
                component: () => import(/* webpackChunkName: "admin-partnership" */ '@/views/spoAdmin/subTabs.vue'),
                props: (route) => ({currentTab:'supplier'}),
                redirect: '/spoAdmin/supplier/list',
                children: [
                    {
                        path: '/spoAdmin/supplier/list',
                        meta: {requireAuth: true, roles: ['SA', 'AP']},
                        component: () => import(/* webpackChunkName: "admin-partnership" */ '@/views/spoAdmin/partnership/supplier/supplierList.vue'),
                    },
                ]
            },
            {
                path: '/spoAdmin/contract',
                meta: {requireAuth: true, roles: ['SA', 'AS','AP']},
                component: () => import(/* webpackChunkName: "admin-partnership" */'@/views/spoAdmin/subTabs.vue'),
                props: (route) => ({currentTab:'contract'}),
                redirect: '/spoAdmin/contract/pi',
                children: [
                    {
                        path: '/spoAdmin/contract/pi',
                        meta: {requireAuth: true, roles: ['SA', 'AS']},
                        component: () => import(/* webpackChunkName: "admin-partnership" */ '@/views/spoAdmin/partnership/contract/PIList.vue')
                    },
                    {
                        path: '/spoAdmin/contract/dc',
                        meta: {requireAuth: true, roles: ['SA', 'AS']},
                        component: () => import(/* webpackChunkName: "admin-partnership" */ '@/views/spoAdmin/partnership/contract/contractList.vue'),
                        props: (route) => ({contractType: 'sale'}),
                    },
                    {
                        path: '/spoAdmin/contract/pc',
                        meta: {requireAuth: true, roles: ['SA', 'AP']},
                        component: () => import(/* webpackChunkName: "admin-partnership" */ '@/views/spoAdmin/partnership/contract/contractList.vue'),
                        props: (route) => ({contractType: 'purchase'}),
                    } 
                ]
            },
            {
                path: '/spoAdmin/product',
                meta: {requireAuth: true, roles: ['SA', 'AS','AP','AD']},
                component: () => import(/* webpackChunkName: "admin-product" */'@/views/spoAdmin/subTabs.vue'),
                props: (route) => ({currentTab: 'product'}),
                redirect: '/spoAdmin/product/specificationList',
                children: [
                    {
                        path: '/spoAdmin/product/productsList',
                        meta: {requireAuth: true, roles: ['SA', 'AS','AP','AD']},
                        component: () => import(/* webpackChunkName: "admin-product" */ '@/views/spoAdmin/products/productsList')
                    },
                    {
                        path: '/spoAdmin/product/brandList',
                        meta: {requireAuth: true, roles: ['SA', 'AS','AP','AD']},
                        component: () => import(/* webpackChunkName: "admin-product" */ '@/views/spoAdmin/products/brandList')
                    },
                    {
                        path: '/spoAdmin/product/specificationList',
                        meta: {requireAuth: true, roles: ['SA', 'AS','AP','AD']},
                        component: () => import(/* webpackChunkName: "admin-product" */ '@/views/spoAdmin/products/specificationList.vue')
                    },
                    
                    
                ]
            },
            {
                path: '/spoAdmin/inventory',
                meta: {requireAuth: true, roles: []},
                component: () => import(/* webpackChunkName: "admin-product" */ '@/views/spoAdmin/subTabs.vue'),
                props: (route) => ({currentTab: 'inventory'}),
                redirect: '/spoAdmin/inventory/list',
                children: [
                    {
                        path: '/spoAdmin/inventory/list',
                        meta: {requireAuth: true, roles: []},
                        component: () => import(/* webpackChunkName: "admin-product" */ '@/views/spoAdmin/products/inventoryList.vue'),
                        props: (route) => ({spec: route.query.spec}),
                    },
                    {
                        path: '/spoAdmin/business/suitList',
                        meta: {requireAuth: true, roles: ['SA', 'AS']},
                        component: () => import(/* webpackChunkName: "admin-business" */ '@/views/spoAdmin/business/suitList.vue'),
                    }
                ]
            },
            {
                path: '/spoAdmin/production',
                meta: {requireAuth: true, roles: []},
                component: () => import(/* webpackChunkName: "admin-product" */ '@/views/spoAdmin/subTabs.vue'),
                props: (route) => ({currentTab: 'production'}),
                redirect: '/spoAdmin/production/list',
                children: [
                    {
                        path: '/spoAdmin/production/list',
                        meta: {requireAuth: true, roles: []},
                        component: () => import(/* webpackChunkName: "admin-product" */ '@/views/spoAdmin/products/production.vue'),
                        // props: (route) => ({spec: route.query.spec}),
                    },
                    {
                        path: '/spoAdmin/production/bomList',
                        meta: {requireAuth: true, roles: ['SA', 'AD']},
                        component: () => import(/* webpackChunkName: "admin-product" */ '@/views/spoAdmin/products/bomList.vue')
                    },
                ]
            },
            {
                path: '/spoAdmin/question',
                meta: {requireAuth: true, roles: []},
                component: () => import(/* webpackChunkName: "admin-question" */ '@/views/spoAdmin/subTabs.vue'),
                props: (route) => ({currentTab: 'question'}),
                redirect: '/spoAdmin/question/list',
                children: [
                    {
                        path: '/spoAdmin/question/list',
                        meta: {requireAuth: true, roles: []},
                        component: () => import(/* webpackChunkName: "admin-question" */ '@/views/spoAdmin/normalQuestion/questionList.vue'),
                    },
                    {
                        path: '/spoAdmin/question/qa',
                        meta: {requireAuth: true, roles: []},
                        component: () => import(/* webpackChunkName: "admin-question" */ '@/views/spoAdmin/normalQuestion/QAList.vue'),
                    },
                ]
            },
            // {
            //     path: '/spoAdmin/message/warningMessage',
            //     meta: {requireAuth: true, roles: ['SA', 'AS','AP','AD','AF']},
            //     component: () => import(/* webpackChunkName: "admin-message" */ '@/views/spoAdmin/message/warningMessage.vue'),
            //     redirect: '/spoAdmin/message/warningMessage/logData',
            //     children: [
            //         {
            //             path: '/spoAdmin/message/warningMessage/logData',
            //             meta: {requireAuth: true, roles: ['SA', 'AS','AP','AD','AF']},
            //             component: () => import(/* webpackChunkName: "admin-message" */ '@/views/spoAdmin/message/logDataWarning.vue'),
            //         },
            //         {
            //             path: '/spoAdmin/message/warningMessage/realData',
            //             meta: {requireAuth: true, roles: ['SA', 'AS','AP','AD','AF']},
            //             component: () => import(/* webpackChunkName: "admin-message" */ '@/views/spoAdmin/message/realDataWarning.vue'),
            //         },
            //         {
            //             path: '/spoAdmin/message/warningMessage/disabled',
            //             meta: {requireAuth: true, roles: ['SA', 'AS','AP','AD','AF']},
            //             component: () => import(/* webpackChunkName: "admin-message" */ '@/views/spoAdmin/message/warningMessageDisabled.vue'),
            //         },
            //     ]
            // },
            {
                path: '/spoAdmin/userMessage',
                meta: {requireAuth: true, roles: []},
                component: () => import(/* webpackChunkName: "admin-message" */ '@/views/spoAdmin/subTabs.vue'),
                props: (route) => ({currentTab: 'userMessage'}),
                redirect: '/spoAdmin/userMessage/list',
                children: [
                    {
                        path: '/spoAdmin/userMessage/list',
                        meta: {requireAuth: true, roles: []},
                        component: () => import(/* webpackChunkName: "admin-message" */ '@/views/spoAdmin/message/userMessage/userMessageList.vue'),
                    },
                    {
                        path: '/spoAdmin/userMessage/type',
                        meta: {requireAuth: true, roles: []},
                        component: () => import(/* webpackChunkName: "admin-message" */ '@/views/spoAdmin/types.vue'),
                        props: (route) => ({questionType: route.query.questionType, types: 'question'}),
                    },
                ]
            },
            // {
            //     path: '/spoAdmin/message/maintenanceMessage',
            //     meta: {requireAuth: true, roles: ['SA', 'AS','AP','AD','AF']},
            //     component: () => import(/* webpackChunkName: "admin-message" */ '@/views/spoAdmin/message/maintenance.vue'),
            //     redirect: '/spoAdmin/message/maintenanceMessage/list',
            //     children: [
            //         {
            //             path: '/spoAdmin/message/maintenanceMessage/list',
            //             meta: {requireAuth: true, roles: ['SA', 'AS','AP','AD','AF']},
            //             component: () => import(/* webpackChunkName: "admin-message" */ '@/views/spoAdmin/message/maintenanceMessage.vue'),
            //         }
            //     ]
            // },
            {
                path: '/spoAdmin/news',
                meta: {requireAuth: true, roles: []},
                component: () => import(/* webpackChunkName: "admin-news" */ '@/views/spoAdmin/subTabs.vue'),
                props: (route) => ({types: 'companyNews',currentTab: 'news'}),
                redirect: '/spoAdmin/news/companyNews',
                children: [
                    {
                        path: '/spoAdmin/news/companyNews',
                        meta: {requireAuth: true, roles: []},
                        component: () => import(/* webpackChunkName: "admin-news" */ '@/views/spoAdmin/news/newsList.vue'),
                        props: (route) => ({types: 'companyNews'}),
                    },
                    {
                        path: '/spoAdmin/news/searchingResult',
                        meta: {requireAuth: true, roles: []},
                        component: () => import(/* webpackChunkName: "admin-news" */ '@/views/spoAdmin/news/newsList.vue'),
                        props: (route) => ({types: 'searchingResult'}),
                    },
                    {
                        path: '/spoAdmin/news/projectSharing',
                        meta: {requireAuth: true, roles: []},
                        component: () => import(/* webpackChunkName: "admin-news" */ '@/views/spoAdmin/news/newsList.vue'),
                        props: (route) => ({types: 'projectSharing'}),
                    },
                ]
            },
            // {
            //     path: '/spoAdmin/shopping/main',
            //     meta: {requireAuth: true, roles: ['SA', 'A']},
            //     component: () => import(/* webpackChunkName: "admin-shopping" */ '@/views/spoAdmin/shopping/shoppingMainPage.vue'),
            // },
            // {
            //     path: '/spoAdmin/shopping/goods',
            //     meta: {requireAuth: true, roles: ['SA', 'A']},
            //     component: () => import(/* webpackChunkName: "admin-shopping" */ '@/views/spoAdmin/shopping/productDetail/product.vue'),
            //     redirect: '/spoAdmin/shopping/goods/list',
            //     children: [
            //         {
            //             path: '/spoAdmin/shopping/goods/list',
            //             meta: {requireAuth: true, roles: ['SA', 'A']},
            //             component: () => import(/* webpackChunkName: "admin-shopping" */ '@/views/spoAdmin/shopping/productDetail/productList.vue'),
            //         },
            //         {
            //             path: '/spoAdmin/shopping/goods/type',
            //             meta: {requireAuth: true, roles: ['SA', 'A']},
            //             component: () => import(/* webpackChunkName: "admin-shopping" */ '@/views/spoAdmin/shopping/productDetail/goodsType.vue'),
            //         },
            //         {
            //             path: '/spoAdmin/shopping/goods/specification',
            //             meta: {requireAuth: true, roles: ['SA', 'A']},
            //             component: () => import(/* webpackChunkName: "admin-shopping" */ '@/views/spoAdmin/shopping/productDetail/specification.vue'),
            //             props: (route) => ({goodsId: route.query.goodsId}),
            //         },
            //         {
            //             path: '/spoAdmin/shopping/goods/discounts',
            //             meta: {requireAuth: true, roles: ['SA', 'A']},
            //             component: () => import(/* webpackChunkName: "admin-accessories" */ '@/views/spoAdmin/shopping/productDetail/discounts.vue'),
            //             props: (route) => ({discountsType: route.query.discountsType}),
            //         },
            //         {
            //             path: '/spoAdmin/shopping/goods/images',
            //             meta: {requireAuth: true, roles: ['SA', 'A']},
            //             component: () => import(/* webpackChunkName: "admin-shopping" */ '@/views/spoAdmin/shopping/productDetail/imageGoods.vue'),
            //             props: (route) => ({cmsId: route.query.cmsId}),
            //         },
            //     ]
            // },
            // {
            //     path: '/spoAdmin/shopping/order',
            //     meta: {requireAuth: true, roles: ['SA', 'A']},
            //     component: () => import(/* webpackChunkName: "admin-shopping" */ '@/views/spoAdmin/shopping/orderDetail/order.vue'),
            //     redirect: '/spoAdmin/shopping/order/list',
            //     children: [
            //         {
            //             path: '/spoAdmin/shopping/order/list',
            //             meta: {requireAuth: true, roles: ['SA', 'A']},
            //             component: () => import(/* webpackChunkName: "admin-shopping" */ '@/views/spoAdmin/shopping/orderDetail/orderList.vue'),
            //         },
            //         {
            //             path: '/spoAdmin/shopping/order/setting',
            //             meta: {requireAuth: true, roles: ['SA', 'A']},
            //             component: () => import(/* webpackChunkName: "admin-shopping" */ '@/views/spoAdmin/shopping/orderDetail/orderSetting.vue'),
            //         },
            //         {
            //             path: '/spoAdmin/shopping/order/refund',
            //             meta: {requireAuth: true, roles: ['SA', 'A']},
            //             component: () => import(/* webpackChunkName: "admin-shopping" */ '@/views/spoAdmin/shopping/orderDetail/refundOrderList.vue'),
            //         },
            //         {
            //             path: '/spoAdmin/shopping/order/refundReasons',
            //             meta: {requireAuth: true, roles: ['SA', 'A']},
            //             component: () => import(/* webpackChunkName: "admin-shopping" */ '@/views/spoAdmin/shopping/orderDetail/refundReasons.vue'),
            //         },
            //     ]
            // },
            {
                path: '/spoAdmin/systemSetting',
                meta: {requireAuth: true, roles: []},
                component: () => import(/* webpackChunkName: "systemSetting" */ '@/views/spoAdmin/subTabs.vue'),
                props: (route) => ({currentTab: 'systemSetting'}),
                redirect: '/spoAdmin/systemSetting/images',
                children: [
                    {
                        path: '/spoAdmin/systemSetting/images',
                        meta: {requireAuth: true, roles: []},
                        component: () => import(/* webpackChunkName: "systemSetting" */ '@/views/spoAdmin/systemSetting/images4systemSetting.vue'),
                        props: (route) => ({flag: 'cms'}),
                    },
                    {
                        path: '/spoAdmin/systemSetting/imageApplication',
                        meta: {requireAuth: true, roles: []},
                        component: () => import(/* webpackChunkName: "systemSetting" */ '@/views/spoAdmin/types.vue'),
                        props: (route) => ({types: 'cms'}),
                    },
                ]
            },
       
        ]
    },
];